import { productVariantFragment } from "@temp/components/ProductsFeaturedCategory/queries";
import gql from "graphql-tag";

import { TypedQuery } from "../../core/queries";
import {
  basicProductFragment,
  productPricingFragment,
  selectedAttributeFragment,
} from "../Product/queries";
import {
  CategoriesList,
  Category,
  CategoryVariables,
} from "./gqlTypes/Category";

export const categoryProductsQuery = gql`
  ${basicProductFragment}
  ${productPricingFragment}
  ${selectedAttributeFragment}
  ${productVariantFragment}
  query Category(
    $id: ID!
    $attributes: [AttributeInput]
    $after: String
    $pageSize: Int
    $sortBy: ProductOrder
    $priceLte: Float
    $priceGte: Float
  ) {
    products(
      after: $after
      first: $pageSize
      sortBy: $sortBy
      filter: {
        attributes: $attributes
        categories: [$id]
        minimalPrice: { gte: $priceGte, lte: $priceLte }
      }
    ) {
      totalCount
      edges {
        node {
          ...BasicProductFields
          ...ProductPricingField
          category {
            id
            name
          }
          attributes {
            ...SelectedAttributeFields
          }
          variants {
            ...ProductVariantFields
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
    category(id: $id) {
      seoDescription
      seoTitle
      descriptionJson
      id
      name
      backgroundImage {
        url
      }
      ancestors(last: 5) {
        edges {
          node {
            id
            name
          }
        }
      }
    }
    attributes(filter: { inCategory: $id }, first: 100) {
      edges {
        node {
          id
          name
          slug
          values {
            id
            name
            slug
          }
        }
      }
    }
  }
`;

export const TypedCategoryProductsQuery = TypedQuery<
  Category,
  CategoryVariables
>(categoryProductsQuery);

////////////////////////////////////////////////////////////

export const categoriesQuery = gql`
  query CategoriesList {
    categories(level: 0, first: 10) {
      edges {
        node {
          id
          name
          backgroundImage {
            url
          }
        }
      }
    }
  }
`;
export const TypedCategoriesQuery = TypedQuery<CategoriesList, {}>(
  categoriesQuery
);
