import { toLower } from "lodash";
export function maybe<T>(exp: () => T): T | undefined;
export function maybe<T>(exp: () => T, d: T): T;
export function maybe(exp: any, d?: any) {
  try {
    const result = exp();
    return result === undefined ? d : result;
  } catch {
    return d;
  }
}

export function filterNotEmptyArrayItems<TValue>(
  value: TValue | null | undefined
): value is TValue {
  return value !== null && value !== undefined;
}

export function isBirthdayRequired(items: any) {
  const hasBeverages = items?.some(item => {
    let product = item.variant.product;
    let isBeverageType = toLower(product?.category?.name) === "beverages";
    let isBeverageisAttribute = product?.attributes.some(
      item =>
        item.attribute.name === "Beverages Type" &&
        item.values.some(property => property?.name === "alcohol")
    );
    return isBeverageType && isBeverageisAttribute;
  });
  return hasBeverages;
}

export function isMainShareQuantityRequired(items: any) {
  const isSelectedLiveShare = items?.some(item => {
    return item?.variant?.name?.startsWith("Live Lobster");
  });
  return isSelectedLiveShare;
}

export function checkFromRouter(items: any, checkout) {
  return (
    isBirthdayRequired(items) &&
    checkout &&
    checkout.billingAddress &&
    checkout.billingAddress.companyName &&
    checkout.billingAddress.companyName === "N/A"
  );
}
