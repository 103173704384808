import React, { useCallback, useState } from "react";

import {
  InputSelect,
  TextField,
  DateField,
  DateInputField,
} from "@components/molecules";

import * as S from "./styles";
import { PropsWithFormik } from "./types";

export const AddressFormContent: React.FC<PropsWithFormik> = ({
  formRef,
  handleChange,
  handleBlur,
  formId,
  errors,
  handleSubmit,
  values,
  countriesOptions,
  defaultValue,
  setFieldValue,
  includeEmail = false,
  isBirthdayRequired = false,
  isMainShareQuantityRequired = false,
  date,
  setDate,
}) => {
  const basicInputProps = useCallback(
    () => ({ onBlur: handleBlur, onChange: handleChange }),
    [handleChange, handleBlur]
  );

  const fieldErrors: any = {};

  if (errors) {
    errors.map(({ field, message }: { field: string; message: string }) => {
      fieldErrors[field] = fieldErrors[field]
        ? [...fieldErrors[field], { message }]
        : [{ message }];
    });
  }

  return (
    <S.AddressForm id={formId} ref={formRef} onSubmit={handleSubmit}>
      <S.Wrapper>
        {isBirthdayRequired && (
          <S.RowWithOneCellHidden>
            <div>
              If purchasing alcohol, please enter your date of birth to verify
              that you are over 21. We may contact you to submit a digital image
              of your driver's license. The name on your driver's license must
              match the name on your Get Hooked account.
            </div>
          </S.RowWithOneCellHidden>
        )}
        <S.RowWithTwoCellsHidden>
          <TextField
            data-cy="addressFormFirstName"
            name="firstName"
            label="First Name"
            value={values!.firstName}
            autoComplete="given-name"
            errors={fieldErrors!.firstName}
            {...basicInputProps()}
          />
          <TextField
            data-cy="addressFormLastName"
            name="lastName"
            label="Last Name"
            value={values!.lastName}
            autoComplete="family-name"
            errors={fieldErrors!.lastName}
            {...basicInputProps()}
          />
        </S.RowWithTwoCellsHidden>
        <S.RowWithTwoCells>
          {/* <TextField
            data-cy="addressFormCompanyName"
            name="companyName"
            label="Company Name (Optional)"
            value="Ypsilon IT Limited"
            autoComplete="organization"
            errors={fieldErrors!.companyName}
            {...basicInputProps()}
          /> */}
          <TextField
            data-cy="addressFormPhone"
            name="phone"
            label="Phone"
            value="8801720404739"
            autoComplete="tel"
            errors={fieldErrors!.phone}
            {...basicInputProps()}
          />
        </S.RowWithTwoCells>
        <S.RowWithOneCell>
          <TextField
            data-cy="addressFormStreetAddress1"
            name="streetAddress1"
            label="Address line 1"
            value="Test street, test street"
            autoComplete="address-line1"
            errors={fieldErrors!.streetAddress1}
            {...basicInputProps()}
          />
        </S.RowWithOneCell>
        <S.RowWithOneCell>
          <TextField
            data-cy="addressFormStreetAddress2"
            name="streetAddress2"
            label="Address line 2"
            value={values!.streetAddress2}
            // value="Street 1, Street 2"
            autoComplete="address-line2"
            errors={fieldErrors!.streetAddress2}
            {...basicInputProps()}
          />
        </S.RowWithOneCell>
        <S.RowWithTwoCells>
          <TextField
            data-cy="addressFormCity"
            name="city"
            label="City"
            value={values!.city}
            // value="Dhaka"
            autoComplete="address-level1"
            errors={fieldErrors!.city}
            {...basicInputProps()}
          />
          <TextField
            data-cy="addressFormPostalCode"
            name="postalCode"
            label="ZIP/Postal Code"
            // value={values!.postalCode}
            value="1200"
            autoComplete="postal-code"
            errors={fieldErrors!.postalCode}
            {...basicInputProps()}
          />
        </S.RowWithTwoCells>
        <S.RowWithTwoCells>
          <InputSelect
            inputProps={{
              "data-cy": "addressFormCountry",
            }}
            defaultValue={defaultValue}
            label="Country"
            name="country"
            options={countriesOptions}
            value={
              values!.country &&
              countriesOptions &&
              countriesOptions!.find(
                option => option.code === values!.country!.code
              )
            }
            // value="BD"
            onChange={(value: any, name: any) => setFieldValue(name, value)}
            optionLabelKey="country"
            optionValueKey="code"
            errors={fieldErrors!.country}
          />
          <TextField
            data-cy="addressFormCountryArea"
            name="countryArea"
            label="State/province"
            // value={values!.countryArea}
            value="Gulshan"
            autoComplete="address-level2"
            errors={fieldErrors!.countryArea}
            {...basicInputProps()}
          />
        </S.RowWithTwoCells>

        {includeEmail && (
          <S.RowWithOneCellHidden>
            <TextField
              data-cy="addressFormEmail"
              name="email"
              label="Email"
              value={values!.email}
              autoComplete="email"
              errors={fieldErrors!.email}
              {...basicInputProps()}
            />
          </S.RowWithOneCellHidden>
        )}
        <S.RowWithTwoCellsHidden>
          {isMainShareQuantityRequired && (
            <TextField
              name="streetAddress2"
              label="Number of Lobsters"
              value={values!.streetAddress2}
              // value="Street 1, Street 2"
              autoComplete="address-line2"
              errors={fieldErrors!.streetAddress2}
              {...basicInputProps()}
              type="number"
              min="1"
              max="10"
              step="1"
            />
          )}

          {isBirthdayRequired && (
            <TextField
              data-cy="addressFormCompanyName"
              name="companyName"
              label="Birthday (MM/DD/YYYY)"
              value={values!.companyName}
              autoComplete="organization"
              errors={fieldErrors!.companyName}
              {...basicInputProps()}
            />
          )}

          {/* <DateField name="companyName" date={date} setDate={setDate} /> */}
        </S.RowWithTwoCellsHidden>
        {isMainShareQuantityRequired && (
          <S.RowWithTwoCellsHidden>
            Please enter the number of lobsters you'd like to recieve in your
            main share above.
          </S.RowWithTwoCellsHidden>
        )}
      </S.Wrapper>
    </S.AddressForm>
  );
};
