import * as React from "react";

import { NavLink } from "..";
import { TypedSecondaryMenuQuery } from "./queries";

import "./scss/index.scss";

class NavCategories extends React.PureComponent {
  render() {
    return (
      <div className="footer-nav__homepage">
        <div className="container__homepage">
          <TypedSecondaryMenuQuery>
            {({ data }) => {
              return data.shop.navigation.secondary.items.map(item => (
                <div className="footer-nav__homepage__section" key={item.id}>
                  <h4 className="footer-nav__homepage__section-header">
                    <NavLink item={item} />
                  </h4>
                  <div className="footer-nav__homepage__section-content">
                    {item.children.map(subItem => (
                      <p key={subItem.id}>
                        <NavLink item={subItem} />
                      </p>
                    ))}
                  </div>
                </div>
              ));
            }}
          </TypedSecondaryMenuQuery>
        </div>
      </div>
    );
  }
}

export default NavCategories;
