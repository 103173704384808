import * as React from "react";
import Media from "react-media";
import {
  mediumScreen,
  smallScreen,
} from "../../globalStyles/scss/variables.scss";

import { DropdownSelect } from "@components/atoms";

import { NavLink } from "..";
import { TypedSecondaryMenuQuery } from "./queries";

import "./scss/index.scss";
import { useHistory } from "react-router";
import { generateCategoryUrl, generateCollectionUrl } from "@temp/core/utils";

interface IProps {
  categoryCollection?: any;
}

export const NavCategoriesDropDown: React.FC<IProps> = ({
  categoryCollection,
}: IProps) => {
  let history = useHistory();
  return (
    <TypedSecondaryMenuQuery>
      {({ data }) => {
        const categoryNames = data.shop.navigation.secondary.items.map(item => {
          return { label: item.name, value: item.name, item: item };
        });
        return (
          <DropdownSelect
            onChange={data => {
              const { item } = data;
              let url;

              if (item.category) {
                url =
                  generateCategoryUrl(item.category.id, item.category.name) +
                  "?sortBy=-priority";
              } else if (item.collection) {
                url =
                  generateCollectionUrl(
                    item.collection.id,
                    item.collection.name
                  ) + "?sortBy=-priority";
              }
              if (item.category || item.collection) {
                history.replace(url);
              }
            }}
            options={categoryNames}
            value={categoryNames.find(
              option => option.value === categoryCollection.name
            )}
            labelText="Category : "
          />
        );
      }}
    </TypedSecondaryMenuQuery>
  );
};
