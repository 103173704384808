import { styled } from "@styles";

export const Wrapper = styled.div`
  margin-bottom: 1.4rem;
`;

export const InnerDiv = styled.div`
  margin: auto;
`;

// position: fixed;
// bottom: 0px;
// z-index: 400;

export const Bar = styled.div`
  height: auto;
  width: 100%;
  /* background-color: red; */
  background-color: ${props => props.theme.tile.backgroundColor};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0rem;
  font-size: ${props => props.theme.typography.smallFontSize};
  margin-top: 1rem;
`;

export const Link = styled.a`
  color: red;
  cursor: pointer;
`;

export const LinkJoin = styled.a`
  color: red;
  cursor: pointer;
`;

export const Text = styled.p``;
//Category style
export const BoldText = styled.p`
  font-weight: ${props => props.theme.typography.boldFontWeight};
`;

export const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    text-align: center;
  }
`;

export const RightSide = styled.div`
  height: 1.2rem;
`;

export const FiltersButton = styled.button`
  font-size: ${props => props.theme.typography.smallFontSize};
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const Clear = styled.button`
  padding-left: 2rem;
  cursor: pointer;
  font-size: ${props => props.theme.typography.smallFontSize};
  color: ${props => props.theme.colors.lightFont};
`;
export const Element = styled.span`
  padding-left: 2rem;
`;

export const Filters = styled.span`
  font-weight: ${props => props.theme.typography.boldFontWeight};
  padding: 0 0.6rem;
`;
