import React from "react";
import { Link } from "react-router-dom";

import { Button, Loader } from "@components/atoms";
import { ProductTile } from "@components/molecules";
import { positions, Provider as AlertProvider, useAlert } from "react-alert";
import { Waypoint } from "react-waypoint";

import {
  generateProductUrl,
  isDeadline,
  getAttributeValue,
  categoryNameCustomize,
  deadlineMessage,
} from "../../../../core/utils";

import * as S from "./styles";
import { IProps } from "./types";
import { OverlayContext } from "@temp/components";

export const ProductList: React.FC<IProps> = ({
  products,
  categoryName,
  canLoadMore = false,
  loading = false,
  onLoadMore = () => null,
}: IProps) => {
  const alert = useAlert();

  return (
    <OverlayContext.Consumer>
      {overlayContext => (
        <>
          <S.List>
            {/* {products.map(product => (
  
              
            <Link
              to={generateProductUrl(product.id, product.name)}
              key={product.id}
            >
              <ProductTile product={product} />
            </Link>
          ))} */}

            {products.map((product, index) => {
              if (isDeadline(product.attributes)) {
                return (
                  <div
                    key={product.id}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      if (!alert) return;
                      alert.show(
                        {
                          // actionText: "Continue Shopping"
                          content: deadlineMessage(
                            categoryName,
                            product.attributes
                          ),
                          title: "Order deadline passed!",
                        },
                        {
                          timeout: 3000,
                          type: "error",
                        }
                      );
                    }}
                  >
                    <ProductTile product={product} deadlinePassed={true} />
                  </div>
                );
              } else {
                return (
                  <ProductTile
                    product={product}
                    overlayContext={overlayContext}
                    showPlusButton={true}
                    deadlinePassed={false}
                  />
                );
              }
            })}
          </S.List>
          <S.Loader>
            {loading ? (
              <Loader />
            ) : (
              // canLoadMore && (
              //   <Button
              //     data-cy="load-more_button"
              //     color="secondary"
              //     onClick={onLoadMore}
              //   >
              //     More +
              //   </Button>
              // )

              canLoadMore && <Waypoint onEnter={onLoadMore} />
            )}
          </S.Loader>
        </>
      )}
    </OverlayContext.Consumer>
  );
};
