import { media, styled } from "@styles";

export const Loader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
  z-index: 10;
`;

export const Wrapper = styled.div`
  margin: 45px 0;
  display: grid;

  grid-template-columns: 8fr 4fr;
  grid-template-rows: 85px auto auto;
  grid-column-gap: 30px;
  grid-template-areas:
    "navigation cartSummary"
    "checkout cartSummary"
    "button cartSummary";

  ${media.mediumScreen`
    grid-template-columns: 1fr;
    grid-template-areas:
      "navigation"
      "checkout"
      "button";
  `}
`;

export const Navigation = styled.div`
  grid-area: navigation;
  border-bottom: 1px solid
    ${props => props.theme.colors.baseFontColorTransparent};
  padding-bottom: 43px;
  padding-left: 1.5rem;
  padding-right: 1rem;
  height: 85px;
`;
export const Checkout = styled.div`
  grid-area: checkout;
  padding: 3rem 0 0 0;
`;
export const CartSummary = styled.div`
  grid-area: cartSummary;

  ${media.mediumScreen`
    position: fixed;
    bottom: 0;
  `}
`;
export const Button = styled.div`
  grid-area: button;
`;

export const HowThisWorks = styled.div`
  margin: 4rem 0
  ${media.smallScreen`
  margin: 7rem 0 3rem;
  `}

  padding-top: 1rem;
  border-top: 1px solid rgba(50, 50, 50, 0.1);
  border-bottom: 1px solid rgba(50, 50, 50, 0.1);

  width: 40rem;
  ${media.smallScreen`
    width: 100%;
  `}

  h1{
    font-size: 32px;
  }

  ul {
    margin: 1rem 0 0 1.7rem;
    text-indent: -1.7em;
  }

  li {
    margin-bottom: 30px;
    font-size: 16px;
  }

  li::before {
    content: "•";
    margin-right: 20px;
    color: ${props => props.theme.colors.listBullet};
  }

`;