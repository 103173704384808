import "./scss/index.scss";

import classNames from "classnames";
import * as React from "react";
import { Link } from "react-router-dom";

import {
  Button,
  Loader,
  ProductsFeatured,
  ProductsFeaturedCategory,
} from "../../components";
import { generateCategoryUrl } from "../../core/utils";

import Media from "react-media";

import {
  mediumScreen,
  smallScreen,
} from "../../globalStyles/scss/variables.scss";

import {
  ProductsList_categories,
  ProductsList_shop,
  ProductsList_shop_homepageCollection_backgroundImage,
} from "./gqlTypes/ProductsList";

import {
  MenuDropdown,
  Offline,
  Online,
  OverlayContext,
  OverlayTheme,
  OverlayType,
  NavCategories,
} from "../../components";

import { structuredData } from "../../core/SEO/Homepage/structuredData";

import noPhotoImg from "../../images/no-photo.svg";

import { checkValidity, homePageDateFormat } from "../../@next/utils/DateUtil";
import { items } from "@temp/@next/components/organisms/SideNavbar/fixtures";
import { mainSharePrefix } from "@temp/core/config";

const Page: React.FC<{
  loading: boolean;
  categories: ProductsList_categories;
  backgroundImage: ProductsList_shop_homepageCollection_backgroundImage;
  shop: ProductsList_shop;
}> = ({ loading, categories, backgroundImage, shop }) => {
  const categoriesExist = () => {
    return categories && categories.edges && categories.edges.length > 0;
  };

  const getCategoryObject = (allCategories, name) =>
    allCategories.find(({ node: category }) => category.name === name);

  const getIndex = (allCategories, name) =>
    allCategories.findIndex(({ node: category }) => category.name === name);

  const isStoreClosed = checkValidity();
  // const [bannerDate, setBannerDate] = React.useState("");
  // React.useEffect(() => {
  //   setBannerDate(homePageDateFormat());
  // }, []);

  const insertByValidity = (array, item) => {
    if (item) {
      array.push(item);
    }
  };

  const getSortedCategories = categories => {
    let sorted = [];
    const allCategories = [...categories.edges];
    const mainShare = getCategoryObject(allCategories, mainSharePrefix);
    const seafood = getCategoryObject(allCategories, "Seafood");
    const beverages = getCategoryObject(allCategories, "Beverages");
    const meat = getCategoryObject(allCategories, "Meat");
    const bread = getCategoryObject(allCategories, "Bakery");
    const cheese = getCategoryObject(allCategories, "Cheese");
    const pantryItem = getCategoryObject(allCategories, "Pantry");
    const bodyHome = getCategoryObject(allCategories, "Home");
    const giftBundle = getCategoryObject(allCategories, "Gifts and Bundles");
    if (mainShare) {
      sorted.push(mainShare);
    }
    if (seafood) {
      sorted.push(seafood);
    }
    if (beverages) {
      sorted.push(beverages);
    }
    if (meat) {
      sorted.push(meat);
    }
    if (bread) {
      sorted.push(bread);
    }
    if (cheese) {
      sorted.push(cheese);
    }
    if (pantryItem) {
      sorted.push(pantryItem);
    }
    if (bodyHome) {
      sorted.push(bodyHome);
    }
    if (giftBundle) {
      sorted.push(giftBundle);
    }

    //sorted.push(seafood);
    // sorted.push(beverages);
    // sorted.push(bread);
    // sorted.push(meat);
    // sorted.push(cheese);
    // sorted.push(pantryItem);
    // sorted.push(bodyHome);
    // sorted.push(giftBundle);
    // sorted.push(utensils);
    return sorted;
  };

  return (
    <OverlayContext.Consumer>
      {overlayContext => (
        <>
          <script className="structured-data-list" type="application/ld+json">
            {structuredData(shop)}
          </script>
          <div
            className="home-page__hero"
            style={
              backgroundImage
                ? { backgroundImage: `url(${backgroundImage.url})` }
                : null
            }

            // style={
            //   backgroundImage
            //     ? {
            //         backgroundImage: `url("http://45.33.109.25:8000/media/collection-backgrounds/clothing.jpg")`,
            //       }
            //     : null
            // }
          >
            {isStoreClosed ? (
              <div className="home-page__hero-text">
                <div>
                  <span className="home-page__hero__title">
                    <h1>Out Fishing</h1>
                  </span>
                </div>
                <div>
                  <span className="home-page__hero__title">
                    <h3>
                      The store will re-open on Friday at 2 pm, and more fresh
                      seafood may be added at any time throughout the week.
                      <br></br>
                      Refresh your page to see see what we've caught.
                    </h3>
                  </span>
                </div>
                {/* <div>
              <span className="home-page__hero__title">
                <h4>For September 2-3 Delivery</h4>
              </span>
            </div> */}
              </div>
            ) : (
              <div className="home-page__hero-text">
                <div>
                  <span className="home-page__hero__title">
                    <h1>Shop Get Hooked</h1>
                  </span>
                </div>
                <div>
                  <span className="home-page__hero__title">
                    <h4>A curated selection of artisanal local products</h4>
                  </span>
                </div>
                <div>
                  <span className="home-page__hero__title">
                    <h3>
                      For delivery {homePageDateFormat()} to{" "}
                      <a
                        target="_blank"
                        href="https://gethookedseafood.com/pre-sign-up-form/"
                      >
                        Members Only
                      </a>
                    </h3>
                  </span>
                </div>
              </div>
            )}
            <div className="home-page__hero-action">
              {loading && !categories ? (
                <Loader />
              ) : (
                categoriesExist() && (
                  <Link
                    to={generateCategoryUrl(
                      categories.edges[0].node.id,
                      categories.edges[0].node.name
                    )}
                  >
                    <Button className="home-page__hero-action-button">
                      Shop Fresh Catch
                    </Button>
                  </Link>
                )
              )}
            </div>
          </div>

          <Media
            query={{ minWidth: mediumScreen }}
            render={() => (
              <div>
                <NavCategories />
              </div>
            )}
          />
          {categoriesExist() && (
            <div className="home-page__categories">
              <div className="container">
                <ProductsFeatured />
                {/* <h3>Shop by category</h3> */}

                <div className="home-page__categories__list">
                  {getSortedCategories(categories).map(({ node: category }) => (
                    <div key={category.id}>
                      <ProductsFeaturedCategory
                        category={category}
                        overlayContext={overlayContext}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}

          {/* {categoriesExist() && (
        <div className="home-page__categories">
          <div className="container">
            <h3>Shop by category</h3>
            <div className="home-page__categories__list">
              {categories.edges.map(({ node: category }) => (
                <div key={category.id}>
                  <Link
                    to={generateCategoryUrl(category.id, category.name)}
                    key={category.id}
                  >
                    <div
                      className={classNames(
                        "home-page__categories__list__image",
                        {
                          "home-page__categories__list__image--no-photo": !category.backgroundImage,
                        }
                      )}
                      style={{
                        backgroundImage: `url(${
                          category.backgroundImage
                            ? category.backgroundImage.url
                            : noPhotoImg
                        })`,
                      }}
                    />
                    <h3>{category.name}</h3>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      )} */}
        </>
      )}
    </OverlayContext.Consumer>
  );
};

export default Page;
