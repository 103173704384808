import "./scss/index.scss";

import isEqual from "lodash/isEqual";
import * as React from "react";

import { Thumbnail } from "@components/molecules";

import { TaxedMoney } from "../../@next/components/containers";
import { BasicProductFields } from "../../views/Product/gqlTypes/BasicProductFields";
// import soldOutThumb from "../../../../images/sold_out.png";

import soldOutThumb from "images/sold_out.png";

import moreThumb from "images/more_thumb.png";

// import { Icon, IconButton } from "@components/atoms";

export interface Product extends BasicProductFields {
    isAvailable: boolean;
    category?: {
        id: string;
        name: string;
    };
    pricing: {
        priceRange: {
            start: {
                gross: {
                    amount: number;
                    currency: string;
                };
                net: {
                    amount: number;
                    currency: string;
                };
            };
            stop: {
                gross: {
                    amount: number;
                    currency: string;
                };
                net: {
                    amount: number;
                    currency: string;
                };
            };
        };
        priceRangeUndiscounted: {
            start: {
                gross: {
                    amount: number;
                    currency: string;
                };
                net: {
                    amount: number;
                    currency: string;
                };
            };
        };
    };
}

interface ProductListItemProps {
    product: Product;
    linkCategory: boolean;
}

const ProductListItem: React.FC<ProductListItemProps> = ({
    product,
    linkCategory = false,
}) => {
    const { category } = product;
    // const price = product.pricing.priceRange.start;
    const priceUndiscounted = product.pricing.priceRangeUndiscounted.start;

    // console.log(product);

    const startPrice =
        product.pricing &&
            product.pricing.priceRange &&
            product.pricing.priceRange.start
            ? product.pricing.priceRange.start
            : undefined;

    const endPrice =
        product.pricing &&
            product.pricing.priceRange &&
            product.pricing.priceRange.stop
            ? product.pricing.priceRange.stop
            : undefined;

    // if (product.category?.name === "Seafood") {
    //   startPrice?.gross.amount = product.basePrice?.amount;
    //   startPrice?.net.amount = product.basePrice?.amount;
    // }

    // const rangeOrNot = () =>
    //   product.category?.name !== "Seafood" && !isEqual(startPrice, endPrice);
    // const price = startPrice;

    const rangeOrNot = () => !isEqual(startPrice, endPrice);
    const price = startPrice;

    // const getProductPrice = () => {
    //   if (isEqual(price, priceUndiscounted)) {
    //     return <TaxedMoney taxedMoney={price} />;
    //   } else {
    //     return (
    //       <>
    //         <span className="product-list-item__undiscounted_price">
    //           <TaxedMoney taxedMoney={priceUndiscounted} />
    //         </span>
    //         &nbsp;&nbsp;&nbsp;&nbsp;
    //         <TaxedMoney taxedMoney={price} />
    //       </>
    //     );
    //   }
    // };

    const getProductPrice = () => {
        if (rangeOrNot()) {
            return (
                <>
                    <TaxedMoney taxedMoney={price} />
                        &nbsp;-&nbsp;
                    <TaxedMoney taxedMoney={endPrice} />
                </>
            );
        } else {
            return <TaxedMoney taxedMoney={price} />;
        }
    };

    return (
        <div className="product-list-item">
            {/* {console.log(product)} */}
            {/* <div onClick={() => console.log("hello")}>
        <Icon size={16} name="plus" />
      </div> */}
            {!linkCategory && product.isAvailable ? (
                <div className="product-list-item__image">
                    <Thumbnail source={product} />
                </div>
            ) : (
                    <>
                        <div
                            className={
                                linkCategory
                                    ? "product-list-item__moreImage"
                                    : "product-list-item__imageSoldOut"
                            }
                            style={{
                                backgroundImage: `url(${product.thumbnail?.url ||
                                    "images/no-photo.svg"})`,
                                backgroundSize: "cover",
                            }}
                        >
                            {!linkCategory && <img src={soldOutThumb} />}
                        </div>
                        {linkCategory && (
                            <img className="product-list-item__frontImage" src={moreThumb} />
                        )}
                    </>
                )}

            {!linkCategory && (
                <>
                    <h4 className="product-list-item__title">{product.name}</h4>
                    {/* <p className="product-list-item__category">{category?.name}</p> */}
                    <p className="product-list-item__price">{""}</p>
                </>
            )}
        </div>
    );
};

export default ProductListItem;
