import React from "react";

import { Media } from "react-media";
import { Chip, DropdownSelect, Icon } from "@components/atoms";
import { NavCategoriesPage, NavCategoriesDropDown } from "../../../../components";

import * as S from "./styles";
import { IProps } from "./types";

export const ProductListHeader: React.FC<IProps> = ({
  numberOfProducts = 0,
  openFiltersMenu,
  clearFilters,
  activeSortOption,
  category,
  activeFilters = 0,
  activeFiltersAttributes = [],
  sortOptions,
  onChange,
  onCloseFilterAttribute,
}: IProps) => {
  //   $medium-screen: 992px;
  // $small-screen: 540px;

  console.log(
    "ProductListHeader: ------------------------ ",
    category,
    activeFiltersAttributes
  );

  return (
    <S.Wrapper>
      <S.Bar>
        {/* <S.LeftSide>
          <S.FiltersButton onClick={openFiltersMenu} data-cy="filters__button">
            <Icon name="filter" size={24} />
            <S.Filters>
              FILTERS{" "}
              {activeFilters > 0 && (
                <>
                  <span>({activeFilters})</span>
                </>
              )}
            </S.Filters>
          </S.FiltersButton>
          {activeFilters > 0 && (
            <S.Clear onClick={clearFilters}>CLEAR FILTERS</S.Clear>
          )}
        </S.LeftSide> */}

        {/* <S.RightSide>
          <S.Element data-cy="no-of-products-found_label">
            <S.Label>Products found: </S.Label>
            {numberOfProducts}
          </S.Element>
          <S.Element>
            <S.Sort>
              {console.log(sortOptions)}
              {console.log(activeSortOption)}
              <DropdownSelect
                onChange={onChange}
                options={sortOptions}
                value={sortOptions.find(
                  option => option.value === activeSortOption
                )}
              />
            </S.Sort>
          </S.Element>
        </S.RightSide> */}

        <S.RightSide>
          <S.Element>
            <S.Sort>
              <NavCategoriesDropDown categoryCollection={category} />
            </S.Sort>
          </S.Element>
        </S.RightSide>
      </S.Bar>
      <S.FiltersChipsWrapper>
        {activeFiltersAttributes.map(
          ({ attributeSlug, valueName, valueSlug }, key) => (
            <Chip
              key={key}
              onClose={() => onCloseFilterAttribute(attributeSlug, valueSlug)}
            >
              {valueName}
            </Chip>
          )
        )}
      </S.FiltersChipsWrapper>
    </S.Wrapper>
  );
};
