import React from "react";

import * as S from "./styles";
import { IProps } from "./types";
import { spacer } from "@temp/@next/globalStyles/constants";

import _ from "lodash";
import { Link } from "react-router-dom";
import { categoryPageDateFormatted } from "../../../utils/DateUtil";
import { RichTextContent } from "../../atoms";

export const CategoryPageFooter: React.FC<IProps> = ({
  categoryName,
  categoryCollection,
  category,
}: IProps) => {
  const getMessage = (name: string) => {
    return categoryCollection[_.camelCase(name)];
  };

  return (
    <S.Wrapper>
      <S.Bar>
        <S.LeftSide>
          {category?.descriptionJson && (
            <S.BoldText>
              <b>
                {" "}
                <RichTextContent descriptionJson={category.descriptionJson} />
              </b>
            </S.BoldText>
          )}
          <p>{categoryPageDateFormatted()}</p>
          <p>
            Please{" "}
            <S.LinkJoin
              href="https://gethookedseafood.com/pre-sign-up-form/"
              target="_blank"
            >
              JOIN US
            </S.LinkJoin>{" "}
            if you are not yet a Get Hooked member!
          </p>
        </S.LeftSide>
      </S.Bar>
    </S.Wrapper>
  );
};
