import React from "react";

import { Loader } from "@components/atoms";

import { Container } from "../Container";

import * as S from "./styles";
import { IProps } from "./types";

/**
 * Template for checkout page.
 */
const Checkout: React.FC<IProps> = ({
  loading,
  navigation,
  checkout,
  cartSummary,
  button,
}: IProps) => {
  return (
    <Container>
      {loading && (
        <S.Loader>
          <Loader fullScreen={true} />
        </S.Loader>
      )}
      <S.Wrapper>
        <S.Navigation>{navigation}</S.Navigation>
        <S.Checkout>{checkout}</S.Checkout>
        <S.CartSummary>{cartSummary}</S.CartSummary>
        <S.Button>{button}</S.Button>
        <S.HowThisWorks>
          <h1>How this works:</h1>
          <ul>
            <li>
              If you upgraded your main share, the price listed is the PER POUND
              price. Your exact charge will be calculated at the end of the week
              based on the size of your main share.
            </li>
            <li>
              If you haven’t selected a Main Share Choice, your catch-of-the-day
              species will be announced the day before delivery in an email
              newsletter.
            </li>
            <li>
              Any items other than "Your Main Share Choices" are additional to
              your scheduled main share.{" "}
            </li>
            <li>Orders are taken until midnight the night before delivery.</li>
            <li>
              These items will be sent to the pick up site or home delivery
              address on file in your Farmigo customer account. Ensure that the
              details you enter match those in your account.{" "}
              <a
                style={{
                  color: "#006169",
                  fontWeight: "bold",
                  textDecoration: "underline",
                }}
                href="https://gethookedseafood.com/pickup-sites/"
                target="_blank"
              >
                See site hours here
              </a>
              .
            </li>
            <li>
              Contact us by{" "}
              <a
                style={{
                  color: "#006169",
                  fontWeight: "bold",
                  textDecoration: "underline",
                }}
                href="mailto:info@gethookedseafood.com"
                target="_blank"
              >
                email
              </a>{" "}
              or 805-209-2019 if you have questions.
            </li>
          </ul>
        </S.HowThisWorks>
      </S.Wrapper>
    </Container>
  );
};

export { Checkout };
