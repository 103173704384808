import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import isEqual from "lodash/isEqual";

import { TaxedMoney } from "@components/containers";
import { Thumbnail } from "@components/molecules";

import * as S from "./styles";
import { IProps } from "./types";
import { Icon, IconButton } from "@components/atoms";

import soldOutThumb from "../../../../images/sold_out.png";
import { ProductVariantPickerHome } from "../../organisms";
import { useCart } from "@temp/@sdk/react";
import { OverlayTheme, OverlayType } from "@temp/components";
import { generateProductUrl } from "@temp/core/utils";
import { positions, Provider as AlertProvider, useAlert } from "react-alert";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import ReactTooltip from "react-tooltip";

import "./scss/index.scss";
import { mainSharePrefix } from "@temp/core/config";
import { checkValidity } from "@temp/@next/utils/DateUtil";

export const ProductTile: React.FC<IProps> = ({
  product,
  overlayContext,
  showPlusButton = false,
  deadlinePassed = false,
}: IProps) => {
  // console.log(product);
  // const price =
  //   product.pricing &&
  //   product.pricing.priceRange &&
  //   product.pricing.priceRange.start
  //     ? product.pricing.priceRange.start
  //     : undefined;
  const { addItem, items } = useCart();
  const alert = useAlert();
  // useEffect(() => {
  //   ReactTooltip.rebuild();
  // });

  let startPrice =
    product.pricing &&
    product.pricing.priceRange &&
    product.pricing.priceRange.start
      ? product.pricing.priceRange.start
      : undefined;

  let endPrice =
    product.pricing &&
    product.pricing.priceRange &&
    product.pricing.priceRange.stop
      ? product.pricing.priceRange.stop
      : undefined;

  // if (product.category?.name === "Seafood") {
  //   startPrice?.gross.amount = product.basePrice?.amount;
  //   startPrice?.net.amount = product.basePrice?.amount;
  // }

  const rangeOrNot = () => !isEqual(startPrice, endPrice);

  const price = startPrice;

  return (
    <S.Wrapper data-cy="product-tile">
      <S.Title>{product.name}</S.Title>
      <S.Price>
        {rangeOrNot() ? (
          <>
            Starting at: <TaxedMoney taxedMoney={price} />
            {/* &nbsp;-&nbsp;
            <TaxedMoney taxedMoney={endPrice} /> */}
          </>
        ) : (
          <TaxedMoney taxedMoney={price} />
        )}
      </S.Price>
      {/* <ReactTooltip
        id={`category-page-tooltip-${product.id}`}
        className="react__tool__tip"
      /> */}
      {product.isAvailable ? (
        <S.PlusAndProduct>
          {deadlinePassed && (
            <S.TopRightIconDeadline
            // data-for={`category-page-tooltip-${product.id}`}
            // data-tip="Deadline passed. Please order before 12 PM Monday."
            >
              <AccessTimeIcon />
            </S.TopRightIconDeadline>
          )}
          {!deadlinePassed &&
            product.variants?.length === 1 &&
            product.variants[0].isAvailable &&
            !checkValidity() && (
              <S.TopRightIcon
                onClick={() => {
                  if (product.variants.length === 1) {
                    if (product.variants[0].isAvailable) {
                      addItem(product.variants[0].id, 1).then(fullfilled => {
                        if (window.innerWidth > 992) {
                          overlayContext.show(
                            OverlayType.cart,
                            OverlayTheme.right
                          );
                        }
                      });

                      alert.show(
                        {
                          actionText: `Continue shopping`,
                          content: `You have added ${product.name} to your cart.`,
                        },
                        {
                          timeout: 3000,
                          type: "success",
                        }
                      );
                    } else {
                      alert.show(
                        {
                          actionText: `Continue shopping`,
                          content: `${product.name} is out of stock.`,
                        },
                        {
                          timeout: 3000,
                          type: "error",
                        }
                      );
                    }
                  }
                }}
              >
                {" "}
                <Icon size={16} name="plus" />{" "}
              </S.TopRightIcon>
            )}

          {!deadlinePassed && product.variants?.length > 1 && (
            <ProductVariantPickerHome
              productVariants={product.variants.filter(
                variant => variant.isAvailable
              )}
              onChange={(_selectedAttributesValues, selectedVariant) => {
                let add = true;
                if (product.name.startsWith(mainSharePrefix)) {
                  let existingProduct = items?.find(
                    item => item.variant?.product?.name === product.name
                  );
                  if (existingProduct) add = false;
                }
                if (add && selectedVariant) {
                  if (selectedVariant.isAvailable) {
                    addItem(selectedVariant.id, 1).then(fullfilled => {
                      if (window.innerWidth > 992) {
                        overlayContext.show(
                          OverlayType.cart,
                          OverlayTheme.right
                        );
                      }
                    });
                    alert.show(
                      {
                        actionText: `Continue shopping`,
                        content: `You have added ${product.name} - ${selectedVariant.name} to your cart.`,
                      },
                      {
                        timeout: 3000,
                        type: "success",
                      }
                    );
                  }
                }
              }}
              selectSidebar={true}
            />
          )}
          <Link
            to={generateProductUrl(product.id, product.name)}
            key={product.id}
          >
            <S.Image>
              <Thumbnail source={product} />
            </S.Image>
          </Link>
        </S.PlusAndProduct>
      ) : (
        <S.ImageSoldOut product={product}>
          <img src={soldOutThumb}></img>
        </S.ImageSoldOut>
      )}
    </S.Wrapper>
  );
};
