import * as React from "react";

import { NavLink } from "..";
import { TypedSecondaryMenuQuery } from "./queries";

import "./scss/index.scss";

class Nav extends React.PureComponent {
  render() {
    return (
      <footer className="footer-nav__homepage">
        {/* <div className="container1"> */}
        {/* <div className="footerTitle1">
            Shop by Category:
          </div> */}
        <div className="container__homepage">
          <TypedSecondaryMenuQuery>
            {({ data }) => {
              return data.shop.navigation.secondary.items.map(item => (
                <div className="footer-nav__homepage__section" key={item.id}>
                  <h4 className="footer-nav__homepage__section-header">
                    <NavLink item={item} />
                  </h4>
                  <div className="footer-nav__homepage__section-content">
                    {item.children.map(subItem => (
                      <p key={subItem.id}>
                        <NavLink item={subItem} />
                      </p>
                    ))}
                  </div>
                </div>
              ));
            }}
          </TypedSecondaryMenuQuery>
        </div>
        {/* </div> */}
        <div className="memo_text">
          <p>
            Choose your main share and add goods made to order from Friday
            afternoon through noon Monday. All other products are available to
            order until the night before delivery, by midnight. The store closes
            after delivery and re-opens at 2 pm every Friday. Freshly-caught
            seafood may be added at any time throughout the week.
          </p>
        </div>
        <div className="footerLogin">
          <a href="https://csa.farmigo.com/account/gethooked">Log in here</a> to
          manage your subscription.
        </div>
      </footer>
    );
  }
}

export default Nav;
