import { development } from "./../../constants";
import { SITE_CLOSE_TIME, testMode } from "../../core/config";
import moment from "moment";
import _ from "lodash";

export function checkValidity() {
  const formatString = "MM/DD/YYYY h:mmA";
  let currentTime = moment();
  if (SITE_CLOSE_TIME["useDateRange"]) {
    let startTime = moment(SITE_CLOSE_TIME["date"]["start"], formatString);
    // console.log(startTime);
    let endTime = moment(SITE_CLOSE_TIME["date"]["end"], formatString);
    // console.log(endTime);
    return currentTime >= startTime && currentTime <= endTime;
  } else {
    // const startDay = SITE_CLOSE_TIME["weekday"]["start"];
    // const endDay = SITE_CLOSE_TIME["weekday"]["end"];
    // let startSplitted = startDay.split(" ");
    // let endSplitted = startDay.split(" ");
    // if (
    //   currentTime.weekday() >= Days[startSplitted[0]] &&
    //   currentTime.weekday() <= Days[startSplitted[0]]
    // ) {
    //   console.log("Site off");

    // }
    const formatDayMins = "ddd h:mmA";
    const startDay = moment(SITE_CLOSE_TIME["weekday"]["start"], formatDayMins);
    const endDay = moment(SITE_CLOSE_TIME["weekday"]["end"], formatDayMins);

    return development ? false : currentTime.isBetween(startDay, endDay);
  }
}

export const WEEK_DAY = {
  Wednesday: 3,
  Thursday: 4,
};

export function nextWeekdayDate(day_in_week: number) {
  //console.log(nextWeekDay());
  let ret = new Date();
  ret.setDate(ret.getDate() + ((day_in_week - 1 - ret.getDay() + 7) % 7) + 1);
  return ret;
}

function findNextDayOfWeekday(dayINeed: number) {
  const today = moment().weekday();
  //let today = moment("10/27/2020 08:30am", "MM/DD/YYYY h:mma").isoWeekday();
  // console.log(today);

  // if we haven't yet passed the day of the week that I need:
  if (today <= dayINeed) {
    //console.log("inside if");
    // then just give me this week's instance of that day
    return moment().weekday(dayINeed);
  } else {
    // otherwise, give me *next week's* instance of that same day
    return moment()
      .add(1, "weeks")
      .weekday(dayINeed);
  }
}

export function nextWeekDay() {
  // let nextWednesday = moment().day(WEEK_DAY.Wednesday + 7);
  // let nextThursday = moment().day(WEEK_DAY.Thursday + 7);

  let nextWednesday = findNextDayOfWeekday(WEEK_DAY.Wednesday);
  let nextThursday = findNextDayOfWeekday(WEEK_DAY.Thursday);
  //console.log(nextWednesday);
  //console.log(nextThursday);
  let nineAm = moment("9:00am", "h:mma");
  let currentDate = moment();

  //testing lines
  // let nineAm = moment("10/14/2020 09:00am", "MM/DD/YYYY h:mma");
  //let currentDate = moment("10/27/2020 08:30am", "MM/DD/YYYY h:mma");

  const nextWedandThu = {
    nextWednesday: nextWednesday,
    nextThursday: nextThursday,
  };
  let dayToRemove: string[] = [];

  if (currentDate.day() == WEEK_DAY.Wednesday && currentDate.isAfter(nineAm)) {
    dayToRemove.push("nextWednesday");
  } else if (currentDate.day() == WEEK_DAY.Thursday) {
    dayToRemove.push("nextWednesday");
    if (currentDate.isAfter(nineAm)) {
      dayToRemove.push("nextThursday");
    }
  }
  const next = _.omit(nextWedandThu, dayToRemove);
  return next;
}

export function categoryPageDateFormatted() {
  const { nextWednesday, nextThursday } = nextWeekDay();
  const suffix = "and are for purchase by MEMBERS ONLY.";

  if (nextThursday) {
    const prefix = "All items are for PICKUP or DELIVERY on";

    if (nextWednesday) {
      return (
        prefix +
        `${nextWednesday.format("M")}/${nextWednesday.format(
          "D"
        )} and ${nextThursday.format("M")}/${nextThursday.format("D")} ` +
        suffix
      );
    }
    return (
      prefix +
      `${nextThursday.format("M")}/${nextThursday.format("D")} ` +
      suffix
    );
  } else {
    return "Store closed " + suffix;
  }
}

export function homePageDateFormat() {
  const { nextWednesday, nextThursday } = nextWeekDay();
  if (nextWednesday && nextThursday) {
    if (nextWednesday?.format("M") == nextThursday?.format("M")) {
      return `${nextWednesday.format("MMMM")} ${nextWednesday.format(
        "D"
      )} & ${nextThursday.format("D")}`;
    } else if (nextWednesday?.format("M") !== nextThursday?.format("M")) {
      return `${nextWednesday.format("MMMM")} ${nextWednesday.format(
        "D"
      )} & ${nextThursday.format("MMMM")} ${nextThursday.format("D")}`;
    }
  } else if (nextThursday) {
    return `${nextThursday.format("MMMM")} ${nextThursday.format("D")}`;
  } else {
    return "Store closed";
  }
}

// if nextWednesday ===
//
//                         today and time is before 9 am, display today and nextThursday,
//                         today and time is after 9 am, don't display Wednesday date (only display nextThursday)
