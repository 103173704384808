import { Formik } from "formik";
import { pick, toLower } from "lodash";
import React, { useState, useEffect } from "react";

import { IAddress } from "@types";
import { AddressFormContent } from "./AddressFormContent";
import { IProps } from "./types";
import { useCart } from "@sdk/react";
import moment from "moment";
import { ICheckoutModelLine } from "@temp/@sdk/repository";
import {
  isBirthdayRequired,
  isMainShareQuantityRequired,
} from "../../../utils/misc";

const ADDRESS_FIELDS = [
  "city",
  "companyName",
  "countryArea",
  "firstName",
  "lastName",
  "country",
  "phone",
  "postalCode",
  "streetAddress1",
  "streetAddress2",
  "email",
];

export const AddressForm: React.FC<IProps> = ({
  address,
  handleSubmit,
  formId,
  defaultValue,
  countriesOptions,
  ...props
}: IProps) => {
  let addressWithPickedFields: Partial<IAddress> = {};
  if (address) {
    addressWithPickedFields = pick(address, ADDRESS_FIELDS);
  }
  if (defaultValue) {
    addressWithPickedFields.country = defaultValue;
  }

  let today = new Date();
  today.setFullYear(today.getFullYear() - 21);
  const [date, setDate] = useState(today);
  // const [isBirthdayRequired, setIsBirthdayRequired] = useState(false);
  const { items } = useCart();
  //console.log(isMainShareQuantityRequired(items));

  // useEffect(() => {
  //   const hasBeverages = items?.some(
  //     item => toLower(item.variant.product?.category?.name) === "beverages"
  //   );
  //   setIsBirthdayRequired(hasBeverages);
  // }, []);

  // console.log(items);
  // const isBirthdayRequired = (items: any) => {
  //   const hasBeverages = items?.some(item => {
  //     let product = item.variant.product;
  //     let isBeverageType = toLower(product?.category?.name) === "beverages";
  //     let isBeverageisAttribute = product?.attributes.some(
  //       item =>
  //         item.attribute.name === "Beverages Type" &&
  //         item.values.some(property => property?.name === "alcohol")
  //     );
  //     return isBeverageType && isBeverageisAttribute;
  //   });
  //   return hasBeverages;
  // };
  // console.log(isBirthdayRequired());
  return (
    <Formik
      initialValues={{ ...addressWithPickedFields }}
      enableReinitialize={true}
      onSubmit={(values, { setSubmitting }) => {
        if (handleSubmit) {
          if (isBirthdayRequired(items)) {
            let currentTime = moment();
            let birthDate = moment(values["companyName"], "MM/DD/YYYY");
            // console.log(birthDate);
            // console.log(!birthDate.isValid());
            // console.log(currentTime.isBefore(birthDate));
            // console.log(currentTime.diff(birthDate, "years") < 21);

            if (
              !birthDate.isValid() ||
              currentTime.isBefore(birthDate) ||
              currentTime.diff(birthDate, "years") < 21
            )
              return;
          } else {
            values["companyName"] = "N/A";
          }

          if (isMainShareQuantityRequired(items)) {
            //console.log(values["streetAddress2"]);
            if (!values["streetAddress2"]) return;
            if (
              values["streetAddress2"] &&
              !isNaN(values["streetAddress2"]) &&
              values["streetAddress2"] < 0
            )
              return;
            values["streetAddress2"] = values["streetAddress2"]?.toString();
          } else {
            values["streetAddress2"] = "N/A";
          }

          values["streetAddress1"] = "1470  Pinewood Avenue";
          values["city"] = "Michigan";
          values["country"] = {
            code: "US",
            country: "United States of America",
          };
          values["postalCode"] = "49855";
          // values["streetAddress2"] = "second street";
          values["countryArea"] = "MI";

          handleSubmit(values);
        }
        setSubmitting(false);
      }}
    >
      {({
        handleChange,
        handleSubmit,
        handleBlur,
        values,
        setFieldValue,
        setFieldTouched,
      }) => {
        return (
          <AddressFormContent
            {...{
              countriesOptions,
              defaultValue,
              formId,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldTouched,
              setFieldValue,
              values,
              isBirthdayRequired: isBirthdayRequired(items),
              isMainShareQuantityRequired: isMainShareQuantityRequired(items),
              date,
              setDate,
            }}
            {...props}
          />
        );
      }}
    </Formik>
  );
};
