import { mediumScreen, smallScreen } from "../../globalStyles/scss/variables.scss";
import "./scss/index.scss";

import NukaCarousel, { CarouselProps } from "nuka-carousel";
import * as React from "react";
import Media from "react-media";
import ReactSVG from "react-svg";

import arrowImg from "../../images/carousel-arrow.svg";
// import { matches } from "lodash";

interface CarouselType extends CarouselProps {
    children: React.ReactNode;
    categoryName: string;
}

const Carousel: React.FC<CarouselType> = ({ children, categoryName, ...rest }) => {
    const settings = {
        className: "carousel",
        renderBottomCenterControls: () => null,
        renderCenterLeftControls: (
            { previousSlide, currentSlide }
        ) => currentSlide !== 0 ?
                (
                    < div onClick={previousSlide} className="carousel__control carousel__control--left" >
                        <ReactSVG path={arrowImg} />
                    </div>
                ) : null,
        renderCenterRightControls
            : ({ nextSlide, currentSlide, slideCount, slidesToShow }) => {
                if (slideCount < slidesToShow) {
                    return null;
                } else if (slideCount - slidesToShow !== currentSlide) {
                    return (
                        < div onClick={nextSlide} style={{ top: '75%' }}
                            className="carousel__control carousel__control--right" >
                            <ReactSVG path={arrowImg} />
                        </div>
                    );
                } else {
                    return null;
                }
            },
        ...rest,
    };
    const carousel = (slides: number) => (
        <NukaCarousel slidesToShow={slides} slidesToScroll={slides} {...settings}>
            {children}
        </NukaCarousel >
    );

    return (
        <Media query={{ maxWidth: smallScreen }} >
            {
                matches => matches ? (carousel(1)) :
                    (
                        < Media query={{ maxWidth: mediumScreen }}  >
                            {matches => carousel(matches ? 2 : 4)}
                        </Media>
                    )
            }
        </Media >

    );
};

export default Carousel;
