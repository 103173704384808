import { media, styled } from "@styles";

export const Wrapper = styled.div`
  margin: 30px 0 100px 0;
`;

export const Breadcrumbs = styled.div``;

export const Title = styled.div`
  margin-top: 80px;
  margin-bottom: 60px;
`;

export const CartHeader = styled.div`
  ${media.mediumScreen`
    display: none;
  `};
`;
export const CartFooter = styled.div``;
export const Cart = styled.div`
  border-top: 1px solid rgba(50, 50, 50, 0.1);
`;
export const CartMessage = styled.div`
  padding-top: 1.5rem;
  font-style: italic;
`;
export const ProceedButton = styled.div`
  text-align: right;
  margin-top: 40px;
`;
export const HowThisWorks = styled.div`
  margin: 3rem 0
  ${media.smallScreen`
  margin: 8rem 0 3rem;
  `}

  padding-top: 1rem;
  border-top: 1px solid rgba(50, 50, 50, 0.1);
  border-bottom: 1px solid rgba(50, 50, 50, 0.1);

  width: 40rem;
  ${media.smallScreen`
    width: 100%;
  `}

  h1{
    font-size: 32px;
  }

  ul {
    margin: 1rem 0 0 1.7rem;
    text-indent: -1.7em;
  }

  li {
    margin-bottom: 30px;
    font-size: 16px;
  }

  li::before {
    content: "•";
    margin-right: 20px;
    color: ${props => props.theme.colors.listBullet};
  }

`;