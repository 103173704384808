import "../globalStyles/scss/index.scss";

import React from "react";
import moment from "moment";

import { Footer, MainMenu, MetaConsumer, OverlayManager } from "../components";
import { Routes } from "./routes";

const App: React.FC = () => {
  React.useEffect(() => {
    // moment.updateLocale("en", {
    //   week: {
    //     dow: 5,
    //     doy: 7 + 5 - 1,
    //   },
    // });
  });

  return (
    <>
      <MetaConsumer />
      <header>
        <MainMenu />
      </header>
      <Routes />
      <Footer />
      <OverlayManager />
    </>
  );
};

export default App;
