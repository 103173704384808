import React, { useState } from "react";

import * as S from "./styles";
import { IProps } from "./types";

import { ErrorMessage, Input } from "@components/atoms";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./DateField.css";

export const DateField: React.FC<IProps> = ({
  errors,
  helpText,
  date,
  setDate,
  ...rest
}: IProps) => {
  // const hasErrors = !!(errors && errors.length);
  // const [startDate, setStartDate] = useState(new Date());

  let maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear() - 21);

  return (
    <>
      <S.TextField>
        {/* <Input {...rest} error={hasErrors} /> */}
        <DatePicker
          selected={date}
          dateFormat="MM/dd/yyyy"
          onChange={newDate => setDate(newDate)}
          showYearDropdown
          scrollableMonthYearDropdown
          maxDate={maxDate}
          className="birthday"
        >
          <div style={{ color: "red" }}>
            Birthday is required for buying Beverages
          </div>
        </DatePicker>

        <S.ErrorMessages>
          <ErrorMessage errors={errors} />
          {helpText && <S.HelpText>{helpText}</S.HelpText>}
        </S.ErrorMessages>
      </S.TextField>
    </>
  );
};

// import React from "react";
// import { useField, useFormikContext } from "formik";
// import DatePicker from "react-datepicker";

// export const DateField = ({ ...props }) => {
//   const { setFieldValue } = useFormikContext();
//   const [field] = useField(props);
//   return (
//     <DatePicker
//       {...field}
//       {...props}
//       selected={(field.value && new Date(field.value)) || null}
//       onChange={(val) => {
//         setFieldValue(field.name, val);
//       }}
//     />
//   );
// };

// export default DateField;
