import { generatePageUrl } from "./utils";

export const BASE_URL = "/";
export const PRODUCTS_PER_PAGE = 6;
export const SUPPORT_EMAIL = "support@example.com";
export const PROVIDERS = {
  BRAINTREE: {
    label: "Braintree",
  },
  DUMMY: {
    label: "Dummy",
  },
  STRIPE: {
    href: "https://js.stripe.com/v3/",
    label: "Stripe",
  },
};
export const STATIC_PAGES = [
  {
    label: "About",
    url: generatePageUrl("about"),
  },
];
export const SOCIAL_MEDIA = [
  {
    ariaLabel: "facebook",
    href: "https://www.facebook.com/gethookedseafood/",
    path: require("../images/facebook-icon.svg"),
  },
  {
    ariaLabel: "instagram",
    href: "https://www.instagram.com/gethookedseafood/",
    path: require("../images/instagram-icon.svg"),
  },
  // {
  //   ariaLabel: "",
  //   href: "",
  //   path: require("../images/twitter-icon.svg"),
  // },
  {
    ariaLabel: "youtube",
    href: "https://www.youtube.com/channel/UC18W-QIwDWeDlK7HZlGqxgA/",
    path: require("../images/youtube-icon.svg"),
  },
];
export const META_DEFAULTS = {
  custom: [],
  description:
    "Community Supported Fishery for sustainable seafood in Santa Barbara and Ventura counties.",
  image: `${window.location.origin}${require("../images/logo.svg")}`,
  title: "Get Hooked Seafood",
  type: "website",
  url: window.location.origin,
};
export enum CheckoutStep {
  Address = 1,
  Shipping,
  Payment,
  Review,
}
export const CHECKOUT_STEPS = [
  {
    index: 0,
    link: "/checkout/address",
    name: "Address",
    nextActionName: "Continue to Shipping",
    nextStepLink: "/checkout/shipping",
    onlyIfShippingRequired: true,
    step: CheckoutStep.Address,
  },
  {
    index: 1,
    link: "/checkout/shipping",
    name: "Shipping",
    nextActionName: "Continue to Payment",
    nextStepLink: "/checkout/payment",
    onlyIfShippingRequired: true,
    step: CheckoutStep.Shipping,
  },
  {
    index: 2,
    link: "/checkout/payment",
    name: "Your details",
    nextActionName: "Continue to Review",
    nextStepLink: "/checkout/review",
    onlyIfShippingRequired: false,
    step: CheckoutStep.Payment,
  },
  {
    index: 3,
    link: "/checkout/review",
    name: "Submit order",
    nextActionName: "Place order",
    nextStepLink: "/order-finalized",
    onlyIfShippingRequired: false,
    step: CheckoutStep.Review,
  },
];

// Days format
// const Days = {
//   Sun: 0,
//   Mon: 1,
//   Tue: 2,
//   Wed: 3,
//   Thu: 4,
//   Fri: 5,
//   Sat: 6,
// };

// redirects the site to specific page during this time range keep the time formatting

export const SITE_CLOSE_TIME = {
  date: {
    // format using moment   MM/DD/YYYY h:mmA
    end: "09/14/2020 01:49AM",
    start: "09/14/2020 01:49AM",
  },
  useDateRange: false,

  weekday: {
    end: "Fri 02:00PM",
    start: "Thu 12:00AM",
  },
};

export const CATEGORY = {
  bakery: {
    message:
      "The deadline to order bread is Monday at noon. Bread will not be sent to Pierre Lafond, Tri-County Produce, Mesa Produce, Lady and Larder, or either of the Broome St. General locations.",
    homepageBottmText: "Order before noon Monday",
  },
  beverages: {
    message:
      "Beverages are for home delivery only. The deadline to order beverages is Monday at noon.",
    homepageBottmText: "Order before noon Monday",
  },
  pantry: {
    message:
      "Pantry items will not be sent to Pierre Lafond, Tri-County Produce, Mesa Produce, Lady and Larder, or either of the Broome St. General locations. Place your order by midnight on the night before delivery, unless another deadline is given.",
    homepageBottmText: "Order some pantry items before noon Monday",
  },
  seafood: {
    message:
      "Order seafood by midnight on the night before delivery, unless another deadline is given.",
    homepageBottmText: "Order some seafood items before noon Monday",
  },
  clickHereToChooseYourMainShare: {
    message:
      "Limit one choice per customer. Choose your main share before Monday at noon. If you do not have a delivery scheduled this week, please choose items in the Seafood category instead.",
    homepageBottmText:
      "Order before noon Monday, or stick with the catch-of-the-week",
  },
  meat: {
    message:
      "Meat will not be sent to Pierre Lafond, Tri-County Produce, Mesa Produce, Lady and Larder, or either of the Broome St. General locations. Place your order by midnight on the night before delivery, unless another deadline is given.",
  },
  cheese: {
    message:
      "Dairy products will not be sent to Pierre Lafond, Tri-County Produce, Mesa Produce, Lady and Larder, or either of the Broome St. General locations. Place your order by midnight on the night before delivery, unless another deadline is given.",
  },
  Home: {
    message:
      "These items will not be sent to Pierre Lafond, Tri-County Produce, Mesa Produce, Lady and Larder, or either of the Broome St. General locations. Place your order by midnight on the night before delivery, unless another deadline is given.",
  },
};

export const COLLECTION = {
  // beerAndWine: {
  //   message:
  //     "Beverages are for home delivery only. The deadline to order beverages is Monday at noon.",
  //   homepageBottmText: "Order before noon Monday",
  // },
  chocolate: {
    message:
      "The deadline to order chocolate is Monday at noon. Chocolate will not be sent to Pierre Lafond, Tri-County Produce, Mesa Produce, Lady and Larder, or either of the Broome St. General locations.",
    homepageBottmText: "Order before noon Monday",
  },
  // tools: {
  //   message:
  //     "These items will not be sent to Pierre Lafond, Tri-County Produce, or Mesa Produce. Place your order before 9 am on your delivery day, unless another deadline is given.",
  // },
};

export const testMode = process.env.TEST_MODE;

export const mainSharePrefix = "Click Here to Choose Your Main Share";
export const mainShareTooltip = "Limit 1 per customer";
export const mainShareProductNamePrefix = "Your Main Share Choices";
