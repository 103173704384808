import * as React from "react";
import { Route, Redirect, Switch } from "react-router-dom";

import { CheckoutLogin, NotFound } from "../../components";
import UserAccount, * as accountPaths from "../../userAccount/routes";
import { OrderDetails } from "../../userAccount/views";
import { Account, AccountConfirm } from "../../views/Account";
import { ArticlePage } from "../../views/Article";
import { CategoryPage } from "../../views/Category";
import { CollectionPage } from "../../views/Collection";
import { HomePage } from "../../views/Home";
import { ProductPage } from "../../views/Product";
import { SearchPage } from "../../views/Search";

import { CartPage, CheckoutPage, PasswordReset, ThankYouPage } from "@pages";

import * as paths from "./paths";

import { checkValidity } from "../../@next/utils/DateUtil";

export const Routes: React.FC = () => {
  const check = () => {
    return true;
  };
  return (
    <Switch>
      <Route exact path={paths.baseUrl} component={HomePage} />
      {checkValidity() && <Redirect to={paths.baseUrl} />}
      <Route path={paths.searchUrl} component={SearchPage} />
      <Route path={paths.categoryUrl} component={CategoryPage} />
      <Route path={paths.collectionUrl} component={CollectionPage} />
      <Route path={paths.productUrl} component={ProductPage} />
      <Route path={paths.cartUrl} component={CartPage} />
      <Route path={paths.checkoutLoginUrl} component={CheckoutLogin} />
      <Route path={paths.pageUrl} component={ArticlePage} />
      <Route path={accountPaths.baseUrl} component={UserAccount} />
      <Route path={accountPaths.userOrderDetailsUrl} component={OrderDetails} />
      <Route path={paths.guestOrderDetailsUrl} component={OrderDetails} />
      <Route path={paths.accountUrl} component={Account} />
      <Route path={paths.accountConfirmUrl} component={AccountConfirm} />
      <Route path={paths.orderHistoryUrl} component={Account} />
      <Route path={paths.addressBookUrl} component={Account} />
      <Route path={paths.passwordResetUrl} component={PasswordReset} />
      <Route path={paths.checkoutUrl} component={CheckoutPage} />
      <Route path={paths.orderFinalizedUrl} component={ThankYouPage} />
      <Route component={NotFound} />
    </Switch>
  );
};

export default Routes;
