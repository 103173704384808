import React from "react";

import AddToCartButton from "./AddToCartButton";
import { positions, useAlert } from "react-alert";
import { cartUrl, checkoutLoginUrl, checkoutUrl } from "../../app/routes";
import { useHistory } from "react-router-dom";
import { useCart } from "@temp/@sdk/react";
import { mainShareProductNamePrefix } from "@temp/core/config";
import { isDeadline } from "@temp/core/utils";

const AddToCart: React.FC<{
  name: string;
  disabled: boolean;
  onSubmit: () => void;
}> = ({ name, onSubmit, disabled }) => {
  const alert = useAlert();
  const { items } = useCart();
  const history = useHistory();

  if (name === mainShareProductNamePrefix) {
    let existingProduct = items?.find(
      item => item.variant?.product?.name === name
    );
    if (existingProduct) disabled = true;
  }

  return (
    <AddToCartButton
      className="product-description__action"
      onClick={() => {
        onSubmit();
        alert.show(
          {
            actionText: "Continue Shopping",
            content: `You have added ${name} to your cart.`,
            noDefault: true,
            onClickActionText: () => {
              history.replace("/");
            },
          },
          {
            timeout: 3000,
            type: "success",
          }
        );
      }}
      disabled={disabled}
    >
      Add to basket
    </AddToCartButton>
  );
};

export default AddToCart;
