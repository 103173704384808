import * as React from "react";
import { Link } from "react-router-dom";

import { useAlert } from "react-alert";

import { IFilters } from "@types";
import { StringParam, useQueryParam } from "use-query-params";
import { Carousel, ProductListItem } from "..";

import {
  convertSortByFromString,
  convertToAttributeScalar,
  deadlineMessage,
  generateCategoryUrl,
  generateProductUrl,
  getDBIdFromGraphqlId,
  getGraphqlIdFromDBId,
  isDeadline,
  maybe,
} from "../../core/utils";
import { TypedCategoryProductsQuery } from "./queries";

import { ProductVariantPickerHome } from "@components/organisms";

import "./scss/index.scss";

import {
  CATEGORY,
  mainSharePrefix,
  mainShareProductNamePrefix,
  PRODUCTS_PER_PAGE,
} from "../../core/config";
// import { date } from "yup";

import _ from "lodash";
// import { Button, Loader } fromfmages/more_thumb.png";
import { checkValidity } from "../../@next/utils/DateUtil";

import { Icon, IconButton } from "@components/atoms";
import { useCart } from "@sdk/react";

import { OverlayContextInterface, OverlayTheme, OverlayType } from "../Overlay";

// import { IProductVariantsAttributesSelectedValues, ITaxedMoney } from "@types";
// import {
// ProductDetails_product_pricing,
// ProductDetails_product_variants,
// ProductDetails_product_variants_pricing,
// } from "@sdk/queries/gqlTypes/ProductDetails";

import Media from "react-media";
import ReactTooltip from "react-tooltip";

import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

import { smallScreen } from "../../globalStyles/scss/variables.scss";

import AccessTimeIcon from "@material-ui/icons/AccessTime";

interface ProductsFeaturedCatgoryProps {
  category?: any;
  overlayContext?: OverlayContextInterface;
}

export const FilterQuerySet = {
  encode(valueObj) {
    const str = [];
    Object.keys(valueObj).forEach(value => {
      str.push(value + "_" + valueObj[value].join("_"));
    });
    return str.join(".");
  },

  decode(strValue) {
    const obj = {};
    const propsWithValues = strValue.split(".").filter(n => n);
    propsWithValues.map(value => {
      const propWithValues = value.split("_").filter(n => n);
      obj[propWithValues[0]] = propWithValues.slice(1);
    });
    return obj;
  },
};

const ProductsFeaturedCategory: React.FC<ProductsFeaturedCatgoryProps> = ({
  category,
  overlayContext,
}) => {
  const alert = useAlert();
  const [sort, setSort] = useQueryParam("sortBy", StringParam);
  const [attributeFilters, setAttributeFilters] = useQueryParam(
    "filters",
    FilterQuerySet
  );
  const { addItem, items } = useCart();
  React.useEffect(() => {
    ReactTooltip.rebuild();
  });

  const clearFilters = () => {
    setAttributeFilters({});
  };

  const onFiltersChange = (name, value) => {
    if (attributeFilters && attributeFilters.hasOwnProperty(name)) {
      if (attributeFilters[name].includes(value)) {
        if (filters.attributes[`${name}`].length === 1) {
          const att = { ...attributeFilters };
          delete att[`${name}`];
          setAttributeFilters({ ...att });
        } else {
          setAttributeFilters({
            ...attributeFilters,
            [`${name}`]: attributeFilters[`${name}`].filter(
              item => item !== value
            ),
          });
        }
      } else {
        setAttributeFilters({
          ...attributeFilters,
          [`${name}`]: [...attributeFilters[`${name}`], value],
        });
      }
    } else {
      setAttributeFilters({
        ...attributeFilters,
        [`${name}`]: [value],
      });
    }
  };

  const getLastProduct = products => {
    // const product = products.length > 5 ? products.pop() : products[0];
    // return product;

    let product;
    // console.log(products.length);
    products.length > 5 ? (product = products.pop()) : (product = products[0]);
    return product;
  };

  const filters: IFilters = {
    attributes: attributeFilters,
    pageSize: PRODUCTS_PER_PAGE,
    priceGte: null,
    priceLte: null,
    sortBy: "-priority",
    // sortBy: sort || null,
  };
  const variables = {
    ...filters,
    attributes: filters.attributes
      ? convertToAttributeScalar(filters.attributes)
      : {},
    id: getGraphqlIdFromDBId(
      `${getDBIdFromGraphqlId(category.id, "Category")}`,
      "Category"
    ),
    pageSize: 15,
    sortBy: convertSortByFromString(filters.sortBy),
  };

  const sortOptions = [
    {
      label: "Clear...",
      value: null,
    },
    {
      label: "Price Low-High",
      value: "price",
    },
    {
      label: "Price High-Low",
      value: "-price",
    },
    {
      label: "Name Increasing",
      value: "name",
    },
    {
      label: "Name Decreasing",
      value: "-name",
    },
    {
      label: "Last updated Ascending",
      value: "updated_at",
    },
    {
      label: "Last updated Descending",
      value: "-updated_at",
    },
    {
      label: "Priority Ascending",
      value: "priority",
    },
    {
      label: "Priority Descending",
      value: "-priority",
    },
  ];

  const carouselMobileCheck = (products, mobileType, linkCategory) => {
    return (
      <Carousel categoryName={category.name} className={category.name}>
        {carouselChildren(products, mobileType)}
        {linkCategory && (
          <div
            style={{
              flexDirection: "column",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Link
              to={{
                pathname: generateCategoryUrl(category.id, category.name),
                search: "?sortBy=-priority",
              }}
              key={category.id}
            >
              <ProductListItem
                product={products[products.length - 1].node}
                linkCategory={true}
              />
            </Link>
          </div>
        )}
      </Carousel>
    );
  };

  const carouselChildren = (products, mobileType = false) => {
    const seafoodArr = [];
    const nonSeafoodArr = [];

    products.forEach(({ node: product }, key) => {
      let ele = null;
      if (isDeadline(product.attributes)) {
        ele = (
          <div className="plus_and_product" key={key}>
            <div className="products-featured-content">
              {!checkValidity() && (
                <div className="add_icon_deadline">
                  <AccessTimeIcon />
                </div>
              )}
              <Link to="" key={product.id}>
                <div
                  //   data-for={`tooltip-${product.id}`}
                  //   data-tip="Deadline Passed. Please order before 12 PM Monday."
                  //   data-iscapture="true"
                  onClick={() => {
                    if (!alert || checkValidity()) {
                      return;
                    }

                    alert.show(
                      {
                        // actionText: "Continue Shopping",
                        content: deadlineMessage(
                          category?.name,
                          product.attributes
                        ),
                        title: "Order deadline passed!",
                      },
                      {
                        timeout: 3000,
                        type: "error",
                      }
                    );
                  }}
                >
                  <ProductListItem product={product} linkCategory={false} />
                </div>
                {!checkValidity() && (
                  <ReactTooltip
                    id={`tooltip-${product.id}`}
                    className="react__tool__tip"
                  />
                )}
              </Link>
            </div>
          </div>
        );
      } else {
        ele = (
          <div className="plus_and_product" key={key}>
            <div className="products-featured-content">
              {" "}
              {product.variants.length === 1 &&
                !checkValidity() &&
                product.variants[0].isAvailable && (
                  <div
                    className="add_icon"
                    onClick={() => {
                      if (product.variants.length === 1) {
                        if (product.variants[0].isAvailable) {
                          addItem(product.variants[0].id, 1).then(
                            fullfilled => {
                              if (window.innerWidth > 992) {
                                overlayContext.show(
                                  OverlayType.cart,
                                  OverlayTheme.right
                                );
                              }
                            }
                          );

                          alert.show(
                            {
                              actionText: `Continue shopping`,
                              content: `You have added ${product.name} to your cart.`,
                            },
                            {
                              timeout: 3000,
                              type: "success",
                            }
                          );
                        } else {
                          alert.show(
                            {
                              actionText: `Continue shopping`,
                              content: `${product.name} is out of stock.`,
                            },
                            {
                              timeout: 3000,
                              type: "error",
                            }
                          );
                        }
                      }
                    }}
                  >
                    <Icon size={16} name="plus" />
                  </div>
                )}
              {/* <ReactTooltip
                id={`add_cart_tooltip-${product.id}`}
                place="bottom"
              >
                <span style={{ zIndex: 10 }}>Add to Cart</span>
              </ReactTooltip> */}
              {product.variants.length > 1 && (
                <ProductVariantPickerHome
                  productVariants={product.variants.filter(
                    variant => variant.isAvailable
                  )}
                  onChange={(_selectedAttributesValues, selectedVariant) => {
                    let add = true;
                    if (product.name.startsWith(mainShareProductNamePrefix)) {
                      const existingProduct = items?.find(
                        item => item.variant?.product?.name === product.name
                      );
                      if (existingProduct) {
                        add = false;
                      }
                    }
                    if (add && selectedVariant) {
                      if (selectedVariant.isAvailable) {
                        addItem(selectedVariant.id, 1).then(fullfilled => {
                          if (window.innerWidth > 992) {
                            overlayContext.show(
                              OverlayType.cart,
                              OverlayTheme.right
                            );
                          }
                        });
                        alert.show(
                          {
                            actionText: `Continue shopping`,
                            content: `You have added ${product.name} - ${selectedVariant.name} to your cart.`,
                          },
                          {
                            timeout: 3000,
                            type: "success",
                          }
                        );
                      }
                    }
                  }}
                  selectSidebar={true}
                />
              )}
              <Link
                to={generateProductUrl(product.id, product.name)}
                key={product.id}
              >
                <ProductListItem product={product} linkCategory={false} />
              </Link>
            </div>
          </div>
        );
      }

      if (product.category.name === "Seafood") {
        seafoodArr.push(ele);
      } else {
        nonSeafoodArr.push(ele);
      }
    });

    if (category.name === "Seafood") {
      const ret = [];
      if (!mobileType) {
        for (let i = 0; i < seafoodArr.length; i += 2) {
          ret.push(
            <div style={{ flexDirection: "column", display: "flex" }}>
              {seafoodArr[i] ? seafoodArr[i] : <></>}
              {seafoodArr[i + 1] ? seafoodArr[i + 1] : <></>}
            </div>
          );
        }
      } else {
        for (const seafoodNode of seafoodArr) {
          ret.push(seafoodNode);
        }
      }
      return ret;
    } else {
      return nonSeafoodArr;
    }
  };

  return (
    <TypedCategoryProductsQuery variables={variables} displayError={false}>
      {({ data }) => {
        const productsOrigin = maybe(() => data.products.edges);
        const products = [...productsOrigin];

        if (products.length) {
          return (
            <div className="products-featured">
              <ReactTooltip
                id={`tooltip-category-${category.id}`}
                className="react__tool__tip"
              />
              <div className="container">
                {category?.name.startsWith(mainSharePrefix) &&
                  products.length >= 1 && (
                    <div>
                      <Link
                        to={generateProductUrl(
                          products[0].node.id,
                          products[0].node.name
                        )}
                        className="mainShareCategoryName"
                        key={category.id}
                        data-for={`tooltip-category-${category.id}`}
                        data-tip={
                          CATEGORY[_.camelCase(category.name)]
                            ?.homepageBottmText
                        }
                        data-iscapture="true"
                      >
                        <div className="categoryHeader">
                          <h3> {category.name} </h3>
                        </div>
                      </Link>
                      <Media
                        query={{ minWidth: smallScreen }}
                        render={() => (
                          <Link
                            to={generateProductUrl(
                              products[0].node.id,
                              products[0].node.name
                            )}
                            key={products[0].node.id}
                          >
                            <ArrowForwardIcon
                              style={{ fontSize: 30, marginLeft: "1rem" }}
                            />
                          </Link>
                        )}
                      />
                      <Media query={{ maxWidth: smallScreen }}>
                        {matches =>
                          matches
                            ? carouselMobileCheck(products, true, false)
                            : carouselMobileCheck(products, false, false)
                        }
                      </Media>
                    </div>
                  )}{" "}
                {!category.name.startsWith(mainSharePrefix) && (
                  <>
                    <Link
                      to={{
                        pathname: generateCategoryUrl(
                          category.id,
                          category.name
                        ),
                        search: "?sortBy=-priority",
                      }}
                      className="categoryNameAnchor"
                      key={category.id}
                      data-for={`tooltip-category-${category.id}`}
                      data-tip={
                        CATEGORY[_.camelCase(category.name)]?.homepageBottmText
                      }
                      data-iscapture="true"
                    >
                      <div className="categoryHeader">
                        <h3> {category.name}</h3>
                      </div>
                    </Link>
                    <Media query={{ maxWidth: smallScreen }}>
                      {matches =>
                        matches
                          ? carouselMobileCheck(products, true, true)
                          : carouselMobileCheck(products, false, true)
                      }
                    </Media>
                  </>
                )}
              </div>
            </div>
          );
        }
        {
          return null;
        }
      }}
    </TypedCategoryProductsQuery>
  );
};

// ProductsFeaturedCategory.defaultProps = {
//   title: "Featured",
// };

export default ProductsFeaturedCategory;
