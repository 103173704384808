import * as React from "react";
import { Link } from "react-router-dom";
import ReactSVG from "react-svg";

import { TaxedMoney } from "@components/containers";
import { Thumbnail } from "@components/molecules";
import { ICheckoutModelLine } from "@sdk/repository";

import { generateProductUrl } from "../../../core/utils";
import removeImg from "../../../images/garbage.svg";
import { CachedImage, TextField } from "@components/molecules";
import { Icon, IconButton } from "@components/atoms";
import CartItem from "./CartItem";

const QuantityButtonsAdd = (add: () => void, index?: number) => (
  // <S.QuantityButtons>
  <div>
    <div onClick={add} data-cy={`cartPageItem${index}QuantityBtnAdd`}>
      <Icon size={10} name="plus" />
    </div>
  </div>

  // </S.QuantityButtons>
);

const QuantityButtonsSubtract = (substract: () => void, index?: number) => (
  // <S.QuantityButtons>
  <div>
    <div
      onClick={substract}
      data-cy={`cartPageItem${index}QuantityBtnSubtract`}
    >
      <Icon size={10} name="horizontal_line" />
    </div>
  </div>

  // </S.QuantityButtons>
);

const ProductList: React.SFC<{
  lines: ICheckoutModelLine[];
  remove(variantId: string): void;
  updateItem: (variantId: string, quantity: number) => any;
}> = ({ lines, remove, updateItem }) => {
  return (
    <ul className="cart__list">
      {lines.map((line, index) => {
        const key = line.id ? `id-${line.id}` : `idx-${index}`;

        return (
          <li key={key}>
            <CartItem line={line} remove={remove} updateItem={updateItem} />
          </li>
        );
      })}
    </ul>
  );
};
export default ProductList;
