import * as React from "react";
import { Link } from "react-router-dom";
import ReactSVG from "react-svg";

import { TaxedMoney } from "@components/containers";
import { Thumbnail } from "@components/molecules";
import { ICheckoutModelLine } from "@sdk/repository";

import { generateProductUrl } from "../../../core/utils";
import removeImg from "../../../images/garbage.svg";
import { CachedImage, TextField } from "@components/molecules";
import { Icon, IconButton } from "@components/atoms";
import ReactTooltip from "react-tooltip";
import {
  mainSharePrefix,
  mainShareProductNamePrefix,
  mainShareTooltip,
} from "@temp/core/config";

const QuantityButtonsAdd = (add: () => void, index?: number) => (
  // <S.QuantityButtons>
  <div
    className="cart__list__item__details__operation__update__add"
    onClick={add}
    data-cy={`cartPageItem${index}QuantityBtnAdd`}
  >
    <Icon size={10} name="plus" />
  </div>

  // </S.QuantityButtons>
);

const QuantityButtonsSubtract = (substract: () => void, index?: number) => (
  // <S.QuantityButtons>
  <div
    className="cart__list__item__details__operation__update__substract"
    onClick={substract}
    data-cy={`cartPageItem${index}QuantityBtnSubtract`}
  >
    <Icon size={10} name="horizontal_line" />
  </div>

  // </S.QuantityButtons>
);

const CartItem: React.SFC<{
  line: any;
  remove(variantId: string): void;
  updateItem: (variantId: string, quantity: number) => any;
}> = ({ line, remove, updateItem }) => {
  // console.log(line.variant);

  const productUrl = generateProductUrl(
    line.variant.product.id,
    line.variant.product.name
  );

  const [tempQuantity, setTempQuantity] = React.useState<string>(
    line.quantity.toString()
  );
  const [isTooMuch, setIsTooMuch] = React.useState(false);
  const onQuantityChange = quantity => updateItem(line.variant.id, quantity);
  let maxQuantity = line.variant.quantityAvailable || line.quantity;

  if (line.variant.product.name.startsWith(mainShareProductNamePrefix)) {
    maxQuantity = 1;
  }

  const handleBlurQuantityInput = () => {
    let newQuantity = parseInt(tempQuantity, 10);

    if (isNaN(newQuantity) || newQuantity <= 0) {
      newQuantity = line.quantity;
    } else if (newQuantity > maxQuantity) {
      newQuantity = maxQuantity;
    }

    if (line.quantity !== newQuantity) {
      onQuantityChange(newQuantity);
    }

    const newTempQuantity = newQuantity.toString();
    if (tempQuantity !== newTempQuantity) {
      setTempQuantity(newTempQuantity);
    }

    setIsTooMuch(false);
  };

  React.useEffect(() => {
    setTempQuantity(line.quantity.toString());
  }, [line.quantity]);

  const add = React.useCallback(
    () => line.quantity < maxQuantity && onQuantityChange(line.quantity + 1),
    [line.quantity]
  );
  const substract = React.useCallback(
    () => line.quantity > 1 && onQuantityChange(line.quantity - 1),
    [line.quantity]
  );
  const handleQuantityChange = (evt: React.ChangeEvent<any>) => {
    const newQuantity = parseInt(evt.target.value, 10);

    setTempQuantity(evt.target.value);

    setIsTooMuch(!isNaN(newQuantity) && newQuantity > maxQuantity);
  };

  const quantityErrors = isTooMuch
    ? [
        {
          message: `Maximum quantity is ${maxQuantity}`,
        },
      ]
    : undefined;

  return (
    <div>
      <div
        className="cart__list__item"
        data-for={
          line.variant.product.name.startsWith(mainShareProductNamePrefix)
            ? "mainShareCartOverlay"
            : "dummy"
        }
        data-tip={mainShareTooltip}
        data-iscapture="true"
      >
        <Link to={productUrl}>
          <div className="cart__list__item__image">
            <Thumbnail source={line.variant.product} />
          </div>
        </Link>
        <div className="cart__list__item__details">
          <p>
            <TaxedMoney taxedMoney={line.variant.pricing.price} />
          </p>
          <Link to={productUrl}>
            <p>{line.variant.product.name}</p>
          </Link>
          <span className="cart__list__item__details__variant">
            <span>{line.variant.name}</span>
            <span>{`Qty: ${line.quantity}`}</span>
          </span>
          <div className="cart__list__item__details__operation">
            <ReactSVG
              path={removeImg}
              className="cart__list__item__details__operation__delete-icon"
              onClick={() => remove(line.variant.id)}
            />
            <TextField
              className="cart__list__item__details__operation__update"
              name="quantity"
              value={tempQuantity}
              onBlur={handleBlurQuantityInput}
              onChange={handleQuantityChange}
              contentRight={QuantityButtonsAdd(add)}
              contentLeft={
                line.quantity !== 1 ? QuantityButtonsSubtract(substract) : <></>
              }
              errors={quantityErrors}
            />
          </div>
        </div>
      </div>
      {line.variant.product.name.startsWith(mainShareProductNamePrefix) && (
        <ReactTooltip id="mainShareCartOverlay" />
      )}
    </div>
  );
};
export default CartItem;
