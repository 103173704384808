import React from "react";

import { ErrorMessage } from "@components/atoms";
import { AddressSummary } from "@components/molecules";

import * as S from "./styles";
import { IProps } from "./types";
import { Link } from "../../atoms/SocialMediaIcon/styles";

/**
 * Review order view showed in checkout.
 */
const CheckoutReview: React.FC<IProps> = ({
  shippingAddress,
  billingAddress,
  shippingMethodName,
  paymentMethodName,
  email,
  errors,
}: IProps) => {
  return (
    <S.Wrapper>
      <S.Title data-cy="checkoutPageSubtitle">Review</S.Title>
      <S.Grid>
        {/* <section>
          <S.SubTitle data-cy="checkoutReviewSectionTitle">
            Email Address
          </S.SubTitle>
          <S.Divider />
          <AddressSummary address={shippingAddress} email={email} />
        </section> */}
        <section>
          <S.SubTitle data-cy="checkoutReviewSectionTitle">
            <a href="">My Get Hooked Account</a></S.SubTitle>
          <S.Divider />
          <AddressSummary address={billingAddress} email={email} />
        </section>
        <section style={{ display: "none" }}>
          <S.SubTitle data-cy="checkoutReviewSectionTitle">
            Shipping Method
          </S.SubTitle>
          <S.Divider />
          <S.TextSummary>{shippingMethodName}</S.TextSummary>
        </section>
        <section style={{ display: "none" }}>
          <S.SubTitle data-cy="checkoutReviewSectionTitle">
            Payment Method
          </S.SubTitle>
          <S.Divider />
          <S.TextSummary>{paymentMethodName}</S.TextSummary>
        </section>
      </S.Grid>
      <S.BottomMessage data-cy="checkoutPageSubtitle">
        <p style={{marginBottom: '20px'}}>If you are a current Get Hooked member, we will deliver these items to you this week. 
        Please <a 
          target="_blank"
          href="https://gethookedseafood.com/pre-sign-up-form/"
          style={{color: '#006169', fontWeight: 'bold', textDecoration: 'underline'}}
        >join now</a> if you are not a member.</p>
        <p>By submitting an order, you
        agree to an automatic charge in the amount of your selection.</p>
      </S.BottomMessage>
      <S.ErrorMessages>
        <ErrorMessage errors={errors} />
      </S.ErrorMessages>
    </S.Wrapper>
  );
};

export { CheckoutReview };
