import { smallScreen } from "../../globalStyles/scss/variables.scss";

import classNames from "classnames";
import * as React from "react";
import Media from "react-media";
import ReactTooltip from "react-tooltip";

import { CachedImage, Thumbnail } from "@components/molecules";
import * as S from "@components/molecules/ProductListHeader/styles";

import {
  Breadcrumbs,
  NavCategoriesPage,
  NavCategoriesDropDown,
  ProductDescription,
} from "../../components";
import {
  generateCategoryUrl,
  generateProductUrl,
  isDeadline,
} from "../../core/utils";

import GalleryCarousel from "./GalleryCarousel";
import { ProductDetails_product } from "./gqlTypes/ProductDetails";

import { ICheckoutModelLine } from "@sdk/repository";

import {
  CategoryPageFooter,
  ProductDescription as NewProductDescription,
} from "../../@next/components/molecules";

import { ProductGallery } from "../../@next/components/organisms/";

import { structuredData } from "../../core/SEO/Product/structuredData";

import {
  CATEGORY,
  mainShareProductNamePrefix,
  mainShareTooltip,
} from "../../core/config";

class Page extends React.PureComponent<
  {
    product: ProductDetails_product;
    add: (variantId: string, quantity: number) => any;
    items: ICheckoutModelLine[];
    history: any;
  },
  { variantId: string }
> {
  fixedElement: React.RefObject<HTMLDivElement> = React.createRef();
  productGallery: React.RefObject<HTMLDivElement> = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      variantId: "",
    };
  }

  //   componentDidMount() {}

  setVariantId = (id: string) => {
    this.setState({ variantId: id });
  };

  get showCarousel() {
    return this.props.product.images.length > 1;
  }

  populateBreadcrumbs = product => [
    {
      link: generateCategoryUrl(product.category.id, product.category.name),
      value: product.category.name,
    },
    {
      link: generateProductUrl(product.id, product.name),
      value: product.name,
    },
  ];

  getImages = () => {
    const { product } = this.props;
    if (product.variants && this.state.variantId) {
      const variant = product.variants
        .filter(variant => variant.id === this.state.variantId)
        .pop();
      if (variant.images.length > 0) {
        return variant.images;
      } else {
        return product.images;
      }
    } else {
      return product.images;
    }
  };

  renderImages = product => {
    const images = this.getImages();
    if (images && images.length) {
      return images.map(image => (
        <a href={image.url} target="_blank">
          <CachedImage url={image.url} key={image.id}>
            <Thumbnail source={product} />
          </CachedImage>
        </a>
      ));
    }
    return <CachedImage />;
  };

  render() {
    const { product } = this.props;
    // console.log(product.attributes);
    if (isDeadline(product.attributes)) {
      this.props.history.push("/");
    }
    // console.log(product.category);
    const productDescription = (
      <ProductDescription
        items={this.props.items}
        productId={product.id}
        name={product.name}
        productVariants={product.variants}
        pricing={product.pricing}
        addToCart={this.props.add}
        setVariantId={this.setVariantId}
        attributes={product.attributes}
      />
    );

    return (
      <div className="product-page">
        <div className="container">
          <CategoryPageFooter
            categoryName={product.category.name}
            categoryCollection={CATEGORY}
            category={product.category}
          />

          <Media
            query={{
              minWidth: smallScreen,
            }}
          >
            {matches =>
              matches ? (
                <div>
                  <NavCategoriesPage />
                </div>
              ) : (
                <S.Wrapper>
                  <S.Bar>
                    <S.RightSide>
                      <S.Element>
                        <S.Sort>
                          <NavCategoriesDropDown
                            categoryCollection={product.category}
                          />
                        </S.Sort>
                      </S.Element>
                    </S.RightSide>
                  </S.Bar>
                </S.Wrapper>
              )
            }
          </Media>

          <Breadcrumbs breadcrumbs={this.populateBreadcrumbs(product)} />
        </div>
        <div className="container">
          <div className="product-page__product">
            {/* Add script here */}
            <script className="structured-data-list" type="application/ld+json">
              {structuredData(product)}
            </script>

            <Media query={{ maxWidth: smallScreen }}>
              {matches =>
                matches ? (
                  <>
                    <GalleryCarousel images={this.getImages()} />
                    <div className="product-page__product__info">
                      {productDescription}
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      className="product-page__product__gallery"
                      ref={this.productGallery}
                    >
                      <div
                        data-for={
                          product.name.startsWith(mainShareProductNamePrefix)
                            ? "mainShareProductDetails"
                            : "dummy"
                        }
                        data-tip={mainShareTooltip}
                        data-iscapture="true"
                      >
                        <ProductGallery
                          images={this.getImages()}
                          isAvailable={product.isAvailable}
                        />
                      </div>
                      {product.name.startsWith(mainShareProductNamePrefix) && (
                        <ReactTooltip id="mainShareProductDetails" />
                      )}
                    </div>

                    <div
                      className="product-page__product__info"
                      data-for={
                        product.name.startsWith(mainShareProductNamePrefix)
                          ? "mainShareProductDetails"
                          : "dummy"
                      }
                      data-tip={mainShareTooltip}
                      data-iscapture="true"
                    >
                      <div
                        className={classNames(
                          "product-page__product__info--fixed"
                        )}
                      >
                        {productDescription}
                      </div>
                    </div>
                  </>
                )
              }
            </Media>
          </div>
        </div>
        <div className="container">
          <div className="product-page__product__description">
            <NewProductDescription
              descriptionJson={product.descriptionJson}
              attributes={product.attributes}
            />
          </div>
        </div>

        {/* <CategoryPageFooter categoryName={product.category.name} /> */}
        {/* {console.log(product.category.products.edges)} */}
        {/* <OtherProducts
          products={product.category.products.edges}
          currentProduct={product}
        /> */}
      </div>
    );
  }
}

export default Page;
