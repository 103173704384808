import { media, styled } from "@styles";
import { css } from "styled-components";
import NoPhoto from "images/no-photo.svg";
import { constants } from "zlib";

const textProps = css`
  font-size: ${props => props.theme.typography.baseFontSize};
  margin: 0 0 0.5rem 0;
  text-align: left;
`;

// export const Wrapper = styled.div`
//   background: ${props => props.theme.colors.light};
//   padding: 2.5rem;
//   text-align: center;
//   max-height: 30rem;
//   transition: 0.3s;

//   :hover {
//     background-color: ${props => props.theme.colors.hoverLightBackground};
//   }

//   ${media.largeScreen`
//     padding: 1.8rem;
//   `}
// `;

export const Wrapper = styled.div`
  padding: 2.5rem;
  text-align: center;
  max-height: 30rem;
  transition: 0.3s;

  :hover {
    background-color: ${props => props.theme.colors.hoverLightBackground};
  }

  ${media.largeScreen`
    padding: 1.8rem;
  `}
`;

export const Title = styled.h4`
  text-transform: none;
  font-weight: normal;
  ${textProps}
`;

export const Price = styled.p`
  ${textProps}
`;

// export const Image = styled.div`
//   width: auto;
//   height: auto;
//   max-width: 100%;
//   > img {
//     width: auto;
//     height: auto;
//     max-width: 100%;
//   }
// `;

export const PlusAndProduct = styled.div`
  width: auto;
  height: auto;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  > a {
    width: 100%;
  }
`;

export const TopRightIcon = styled.div`
  width: 4rem;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: -4rem;
  z-index: 2;
  border: 4px solid ${props => props.theme.colors.primary};
  background-color: ${props => props.theme.colors.primary};
  cursor: pointer;

  svg * {
    fill: ${props => props.theme.colors.light};
    transition: 0.3s;
  }
  &:hover {
    background-color: rgba(222, 233, 234, 0.5);
    border: 4px solid ${props => props.theme.colors.primary};
    svg * {
      fill: ${props => props.theme.colors.primary};
    }
  }
`;

export const PlusAndProduct = styled.div`
  width: auto;
  height: auto;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  > a {
    width: 100%;
  }
`;

export const TopRightIconDeadline = styled.div`
  width: 4rem;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: -4rem;
  z-index: 2;
  border: 4px solid ${props => props.theme.colors.disabled};
  background-color: ${props => props.theme.colors.disabled};
  cursor: pointer;

  svg * {
    fill: ${props => props.theme.colors.secondaryDark};
    transition: 0.3s;
  }
`;

export const Image = styled.div`
  width: auto;
  height: auto;
  max-width: 100%;

  > img {
    width: 100%;
    height: 15rem;
    object-fit: cover;
  }
`;

export const ImageSoldOut = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 15rem;
  max-width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("${props => props.product.thumbnail?.url || NoPhoto}");
  > img {
    object-fit: contain;
    max-width: 80%;
  }
`;
